const initalState = {
  user: {},
};

const user_auth = (state = initalState, actions) => {
  // console.log("reducerss", state);
  switch (actions.type) {
    case "ADD_USER":
      return { ...state, user: actions.user };
    default:
      return state;
  }
};

export default user_auth;
