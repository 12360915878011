import React from "react";

// Components
import HomePage from "./HomePage";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Styles
import "./index.scss";
import { Redirect } from "react-router-dom";

const stripePromise = loadStripe(
	"pk_live_51IqHyKKBt4Fw2XcgUtbp8GxoCEmufo44HXLo9oQms3ZaYsqwKZpxvWJkXktLxlZME8xFvYGoARnP5WtkrfZ5CSEr00OKNNNopn"
);

const PaymentsPage = ({ history }) =>
	JSON.parse(sessionStorage.getItem("vinoted_new_user_details")) ? (
		<Elements stripe={stripePromise}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<HomePage history={{ history }} />
			</div>
		</Elements>
	) : (
		<Redirect to="/" />
	);
export default PaymentsPage;
