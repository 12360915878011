import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import FirebasePush from "./config/FirebasePush";
import Routing from "./config/routes";
import "./index.css";
import "./index.scss";
import store from "./store";
import "react-notifications/lib/notifications.css";

ReactDOM.render(
	// <React.StrictMode>
	<Provider store={store}>
		{/* <App /> */}
		{/* {navigator.userAgent.indexOf("Safari") > -1 ? <FirebasePush /> : ""} */}
		{localStorage.getItem("browser") === "safari" ? "" : <FirebasePush />}
		<Routing />
	</Provider>,
	// </React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
