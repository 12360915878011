import React, { useState } from "react";

// MUI Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// stripe
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";

// Util imports
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Custom Components
import CardInput from "./CardInput";
import Logo2 from "../../assets/img/logo(2).png";
import auth from "../../config/AuthHelper";
import Snackbar from "../../common/Snackbar/Snackbar";
import http from "../../config/http";
import Loader from "../../common/Loader/Loader";

const useStyles = makeStyles({
	root: { maxWidth: "600px" },
	content: {
		display: "flex",
		flexDirection: "column",
		alignContent: "flex-start",
	},
	div: {
		margin: "10px 0",
		display: "flex",
		flexDirection: "row",
		alignContent: "flex-start",
		justifyContent: "space-between",
	},
	radio: { margin: 0, padding: 0, fontSize: 19 },
});

const HomePage = ({ history }) => {
	const classes = useStyles();
	const [error, setError] = useState("");
	const [selectedValue, setSelectedValue] = React.useState("yearly");
	const [state, setState] = useState({
		outer_bar: false,
		open: false,
		loader: false,
		snackbar: { message: "", status: "" },
	});

	const data = JSON.parse(sessionStorage.getItem("vinoted_new_user_details"));

	const stripe = useStripe();
	const elements = useElements();

	const handleSubmitSub = async event => {
		setState({ ...state, loader: true });
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			setState({ ...state, loader: false });
			return;
		}

		setError("");

		const result = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(
				CardNumberElement,
				CardExpiryElement,
				CardCvcElement
			),
			billing_details: {
				email: data.email,
			},
		});

		if (result.error) {
			console.log(result.error.message);
			setError(result.error.message);
			setState({ ...state, loader: false });
			return;
		} else {
			let formData = new FormData();
			const payload = {
				...data,
				payment_method: result.paymentMethod.id,
				plan: selectedValue,
			};
			for (const [key, value] of Object.entries(payload))
				formData.append(key, value);
			http.post("supplier/signup", formData).then(response => {
				if (response.code === 201) {
					let stateNew = { ...state };
					const {
						client_secret,
						status,
						user,
						access_token,
						expires_at,
						token_type,
					} = response.data.data;
					if (status === "requires_action") {
						stripe.confirmCardPayment(client_secret).then(function (result) {
							if (result.error) {
								console.log("There was an issue with the payment!");
								console.log(result.error);
								setError(result.error);
							} else {
								auth.authenticate({
									user,
									access_token,
									expires_at,
									token_type,
								});
								setTimeout(() => {
									sessionStorage.removeItem("vinoted_new_user_details");
									window.location.href = "/";
								}, 2000);
							}
						});
					} else {
						stateNew.outer_bar = true;
						stateNew.open = true;
						stateNew.loader = true;
						stateNew.snackbar.message = response.data.message;
						stateNew.snackbar.status = "success";
						setState(stateNew);
						auth.authenticate({ user, access_token, expires_at, token_type });
						setTimeout(() => {
							sessionStorage.removeItem("vinoted_new_user_details");
							window.location.href = "/";
						}, 2000);
					}
				} else setError(response.message);
				setState({ ...state, loader: false });
			});
		}
	};

	const handlePlanChange = event => {
		setSelectedValue(event.target.value);
	};

	return (
		<Card className={classes.root}>
			<CardContent
				className={classes.content}
				style={{ border: "15px solid #b3b3b3" }}
			>
				{state.loader && <Loader />}
				{state.outer_bar && (
					<Snackbar open={state.open} message={state.snackbar} />
				)}
				<div className="brand-wrapper">
					<img src={Logo2} alt="logo" className="logo" />
				</div>
				<p
					style={{ margin: "10px 0" }}
					className="login-card-description signup-desc"
				>
					{`Hello ${data.first_name}, you'll recive updates and receipts on the email address associated with your account. (${data.email})`}
				</p>
				<p
					style={{ marginBottom: "0px" }}
					className="login-card-description signup-desc"
				>
					Please select a plan:{" "}
				</p>
				<RadioGroup
					aria-label="quiz"
					name="quiz"
					value={selectedValue}
					onChange={handlePlanChange}
				>
					<FormControlLabel
						value="yearly"
						className={classes.radio}
						control={<Radio color="secondary" />}
						label="Yearly - £290.00 GBP / year"
					/>
					<FormControlLabel
						value="monthly"
						className={classes.radio}
						control={<Radio color="secondary" />}
						label="Monthly - £29.00 GBP / month"
					/>
				</RadioGroup>
				<p style={{ margin: "10px 0" }} className="signup-desc">
					{`You'll be charged only after a free trial of 90 days.`}
				</p>
				<p style={{ color: "red" }}>
					{error !== "" && error + ` Please go back try again.`}
				</p>
				<CardInput />
				<div className={classes.div}>
					<button
						style={{
							padding: "13px 20px 12px",
							backgroundColor: "#e63946",
							borderRadius: "20px",
							fontSize: "17px",
							fontWeight: "bold",
							lineHeight: "20px",
							color: "#fff",
						}}
						onClick={handleSubmitSub}
						className="m-4 btn btn-block login-btn"
					>
						Subscribe
					</button>
					<button
						style={{
							padding: "13px 20px 12px",
							backgroundColor: "#e63946",
							borderRadius: "20px",
							fontSize: "17px",
							fontWeight: "bold",
							lineHeight: "20px",
							color: "#fff",
						}}
						onClick={history.history.goBack}
						className="m-4 btn btn-block login-btn"
					>
						Go Back
					</button>
				</div>
			</CardContent>
		</Card>
	);
};

export default HomePage;
