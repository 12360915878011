import React from "react";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			color: "#32325D",
			fontWeight: 500,
			fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",

			"::placeholder": {
				color: "#CFD7DF",
			},
		},
		invalid: {
			color: "#E25950",
		},
	},
};

const CardInput = () => (
	<div style={{ padding: "20px 0" }}>
		<CardNumberElement
			options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "Card Number" }}
		/>
		<p></p>
		<CardExpiryElement
			options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "Expiry Date (MM/YY)" }}
		/>
		<p></p>
		<CardCvcElement options={{ ...CARD_ELEMENT_OPTIONS, placeholder: "CVC" }} />
		<p></p>
	</div>
);

export default CardInput;
